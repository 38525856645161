import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './SetupWithdrawalPage.css';
import {
  getWithdrawalCopy,
  getContributionCopy,
  getPensionCopy,
  ChangedScenario,
} from './Utils';
import { currencyString } from '../../util/currency';

import healthyPlantImg from '../../assets/images/retirementIncome/healthy-plant.svg';
import dyingPlantImg from '../../assets/images/retirementIncome/dying-plant.svg';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import AssumptionsModal from '../../components/AssumptionsModal/AssumptionsModal';

import minusPound from '../../assets/images/rebranding/minus-money.svg';
import addPound from '../../assets/images/rebranding/add-money.svg';
import transferPensionIcon from '../../assets/images/rebranding/tri-tone-overlapping-circles.svg';

import {
  getIntendedRetirementAge, getVerifiedGrossEmployerContributions, getVerifiedNetPersonalContributions, getVerifiedPersonalContributionsValue,
} from '../../redux/selectors';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';

import { getHasInProgressContributionInstructions } from '../../redux/selectors/investmentAdvice';

function WithdrawalResultsStep({
  dispatchResultsStepMessageAdvisorButtonClicked,
  step,
  formData,
  setFormData,
  setStep,
  setAmendMode,
  loading,
  errorMessage,
  currentEstimate,
  newEstimate,
}) {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { currentAnnualIncome, currentEstimatedPensionValueAtRetirement } = currentEstimate;
  const { newAnnualIncome, newEstimatedPensionValueAtRetirement } = newEstimate;

  const annualIncomeDifference = newAnnualIncome - currentAnnualIncome;
  const absoluteAnnualIncomeDifference = Math.abs(annualIncomeDifference);
  const incomeIncrease = annualIncomeDifference >= -1;

  const overallFundDifference = newEstimatedPensionValueAtRetirement - currentEstimatedPensionValueAtRetirement;
  const absoluteOverAllFundDifference = Math.abs(overallFundDifference);

  const assumptionsSubheadings = [];

  return (
    <React.Fragment>
      <div className={classnames(styles.roundedDiv, styles.roundedDivResults)}>
        <div className={styles.roundedDivHeading}>
          <h1 className={styles.resultsHeading}>{'Your results'}</h1>
          <p className={styles.resultsSubtext}>
            {'Your estimated income'}
            <br />
            {' would '}
            <span
              className={classnames({
                [styles.increaseText]: incomeIncrease,
                [styles.decreaseText]: !incomeIncrease,
              })}
            >
              {incomeIncrease ? 'increase by' : 'decrease by'}
              {' '}
              <span className={styles.monetaryValue}>{`${currencyString(absoluteAnnualIncomeDifference)}`}</span>
              {' / year'}
            </span>
          </p>

          <img
            src={incomeIncrease ? healthyPlantImg : dyingPlantImg}
            alt={incomeIncrease ? 'Healthy plant' : 'Dying plant'}
          />
        </div>

        <div className={classnames(styles.incomeTable, {
          [styles.increaseBorder]: incomeIncrease,
          [styles.decreaseBorder]: !incomeIncrease,
        })}
        >
          <div className={styles.incomeTableRow}>
            <div className={styles.incomeTableCell} />
            <div className={classnames(styles.incomeTableCell, styles.incomeTableHeader)}>
              <span>{'Before'}</span>
            </div>
            <div className={classnames(styles.incomeTableCell, styles.incomeTableHeader)}>
              <span>
                {'After'}
              </span>
            </div>
          </div>
          <div className={styles.incomeTableRow}>
            <div className={classnames(styles.incomeTableCell, styles.incomeTableCellLeftAlign)}>
              <span>{'Estimated income / year'}</span>

            </div>
            <div className={classnames(styles.incomeTableCell, styles.incomeTableMonetaryValues)}>
              <span>{`${currencyString(currentAnnualIncome)}*`}</span>
            </div>
            <div className={classnames(styles.incomeTableCell, styles.incomeTableMonetaryValues)}>
              <span>
                {`${currencyString(newAnnualIncome)}*`}
              </span>
            </div>
          </div>
          <div className={styles.incomeTableRow}>
            <div className={classnames(styles.incomeTableCell, styles.incomeTableCellLeftAlign)}>
              <span>{'Estimated overall fund value'}</span>

            </div>
            <div className={classnames(styles.incomeTableCell, styles.incomeTableMonetaryValues)}>
              <span>{`${currencyString(currentEstimatedPensionValueAtRetirement)}*`}</span>
            </div>
            <div className={classnames(styles.incomeTableCell, styles.incomeTableMonetaryValues)}>
              <span>
                {`${currencyString(newEstimatedPensionValueAtRetirement)}*`}
              </span>
            </div>
          </div>
          <div className={styles.incomeTableRow}>
            <div className={classnames(styles.incomeTableCell, styles.incomeTableCellLeftAlign)}><span>{'Difference in overall fund value'}</span></div>
            <div className={classnames(styles.incomeTableCell, styles.incomeTableMonetaryValues)} />
            <div className={classnames(
              styles.incomeTableCell,
              styles.incomeTableMonetaryValues,
              styles.incomeTableMonetaryValuesBottomCell,
              {
                [styles.increaseText]: incomeIncrease,
                [styles.decreaseText]: !incomeIncrease,
              },
            )}
            >
              <span>
                {`${incomeIncrease ? '+' : '-'}${currencyString(absoluteOverAllFundDifference)}*`}
              </span>
            </div>
          </div>

        </div>

        <ButtonLink
          label={'How is it estimated?'}
          variant="primary"
          fontSize={14}
          font="bodyOne"
          onClick={() => {
            setShowModal(true);
          }}
        />

        <p className={styles.resultsPageTextForest}>{'Based on your selections'}</p>
        <ChangedScenario
          title={'Making a withdrawal'}
          icon={minusPound}
          getContent={getWithdrawalCopy}
          functionProps={[
            formData.withdrawalAmount,
          ]}
        />
        <div className={styles.resultPageContactUsWrapper}>
          <ContactUsContent
            showButton={false}
            customMessage={(
              <p
                className={styles.resultPageContactUsMessage}
              >
                <span className={styles.boldText}>
                  {'Making a withdrawal will negatively impact your estimated annual income at retirement'}
                </span>
                {' and may impact your quality of life at retirement. If you have any questions, please feel free to '}
                <ButtonLink
                  label="message me"
                  variant="primary"
                  to="/inbox/new-message"
                  search={'?subject=Withdrawal%20Request'}
                  displayAsHref
                  font="bodyOne"
                  onClick={dispatchResultsStepMessageAdvisorButtonClicked}
                />
                {'.'}
              </p>
            )}
          />
        </div>
        <AssumptionsModal
          show={showModal}
          onClose={() => setShowModal(false)}
          subHeadings={assumptionsSubheadings}
        />
        <div className={styles.suggestionCardBadge}>
          <span>
            {'To balance your pension you may want to...'}
          </span>
        </div>
        <ChangedScenario
          title={'Set up regular contributions'}
          icon={addPound}
          getContent={getContributionCopy}
          functionProps={[
            formData.withdrawalAmount,
          ]}
        />
        <hr />
        <ChangedScenario
          title={'Find & Combine old pensions'}
          icon={transferPensionIcon}
          getContent={getPensionCopy}
          functionProps={[
            formData.withdrawalAmount,
          ]}
        />
      </div>
    </React.Fragment>
  );
}

WithdrawalResultsStep.propTypes = {};

const mapStateToProps = (state) => ({
  currentVerifiedContributionsValue: getVerifiedNetPersonalContributions(state),
  intendedRetirementAge: getIntendedRetirementAge(state),
  hasInProgressContributions: getHasInProgressContributionInstructions(state),
  verifiedGrossEmployerContributions: getVerifiedGrossEmployerContributions(state),
});

export default connect(mapStateToProps)(WithdrawalResultsStep);
