import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './AssumptionsModal.css';
import Modal from '../Modal/Modal';
import GlanceCard from '../GlanceCard/GlanceCard';
import GlanceCardContent from '../GlanceCard/GlanceCardContent';

import arrowDown from '../../assets/images/rebranding/triangle-downward.svg';
import closeIcon from '../../assets/images/rebranding/cross-icon-in-circle.svg';

function AssumptionsModal({ show, onClose, subHeadings }) {
  const [showAssumptions, setShowAssumptions] = useState(false);
  const toggleAssumptions = () => setShowAssumptions((prev) => !prev);

  return (
    <Modal show={show} backDropClick={onClose}>
      <GlanceCard>
        <GlanceCardContent wrapperStyles={{ maxHeight: '86vh' }}>
          <div className={styles.modalHeader}>
            <h3 className={styles.heading}>{'Income estimation'}</h3>
            <div
              className={styles.closeWrapper}
              onClick={() => {
                setShowAssumptions(false);
                onClose();
              }}
              onKeyUp={() => {}}
              role="button"
              tabIndex="0"
            >
              <img src={closeIcon} alt="Close icon" />
              {' '}
              <span className={styles.closeWrapperText}>{'Close'}</span>
            </div>
          </div>
          {subHeadings && (
            <div className={styles.yourPensionPlan}>
              {subHeadings.map((subHeading) => {
                return (
                  <div key={subHeading.title} className={styles.subHeadingWrapper}>
                    <p className={styles.subHeadingTitle}>{subHeading.title}</p>
                    <p className={styles.subHeadingContent}>{subHeading.content}</p>
                  </div>
                );
              })}
            </div>
          )}
          <hr />
          <div className={styles.forecastSection}>
            <h4 className={styles.forecastHeading}>{'Why does the forecast change?'}</h4>
            <ul className={styles.forecastList}>
              {
                [
                  'We periodically update our model to reflect evolving market conditions, investment strategies, and economic landscapes. This ensures that your pension forecast remains aligned with both high and low market scenarios.',
                  'You may notice that your forecast decreases after periods of strong returns - this is to anticipate potential future volatility. Conversely, it may increase when the outlook improves following market declines. These adjustments help provide a balanced and realistic projection of your pension over time. ',
                  'A key part of the service we provide is oversight by our Investment Committee. This committee, made up of experts, carefully reviews market trends, risk factors, and long-term investment strategies. Their expertise helps ensure that your pension remains well-positioned for the future, balancing growth opportunities with risk management to support stable and sustainable outcomes.',
                ].map((text, index) => (
                  <li key={`forecast-item-${index.toString()}`} className={styles.forecastListItem}>
                    <div className={styles.forecastListItemIndex}>{index + 1}</div>
                    <span className={styles.forecastListItemText}>{text}</span>
                  </li>
                ))
              }
              <li className={styles.forecastSupportMessage}>{'As your pension advisers, we are here to support you through your investment journey. Please send us a message if you have any questions.'}</li>
            </ul>
          </div>
          <div className={styles.assumptionsSection}>
            <button
              type="button"
              aria-expanded={showAssumptions}
              className={styles.assumptionsHeader}
              onClick={toggleAssumptions}
            >
              <h4 className={styles.assumptionsHeading}>{'Assumptions to calculate it'}</h4>
              <img
                className={classNames(styles.assumptionsToggleIcon, {
                  [styles.assumptionsToggleIconOpen]: showAssumptions,
                })}
                src={arrowDown}
                alt="Toggle assumptions section"
              />
            </button>
            {showAssumptions && (
              <ul className={styles.assumptionsUnorderedList} style={{ paddingInlineStart: '30px' }}>
                <li className={styles.assumptionsListItem}>{'The calculator is for illustrative purposes only and figures may be higher or lower than those shown. It should not be regarded as personal advice.'}</li>
                <li className={styles.assumptionsListItem}>{'Your final pension fund and the income available will depend on a number of factors including fund performance, contributions made, charges, inflation, your retirement age, the amount you withdraw from your pensions, tax and mortality rates.'}</li>
                <li className={styles.assumptionsListItem}>{'As with all investments the value can fall and rise, therefore you may get back less than you invest.'}</li>
                <li className={styles.assumptionsListItem}>{'All figures take account of inflation and show the buying power of your pension in today’s money.'}</li>
                <li className={styles.assumptionsListItem}>{'We have used an expected annual return after inflation and fees of 1.58% which is the expected return of our growth portfolio modelled on current market conditions.'}</li>
                <li className={styles.assumptionsListItem}>{'The drawdown time period is assumed to be from the age you would like to retire to your life expectancy, based on the latest dataset published by the Office of National Statistics (Expectation of Life, 2018).'}</li>
                <li className={styles.assumptionsListItem}>{'Our expected income per year has been calculated using stochastic data to simulate the probability that you would be able to maintain that income taking into account market conditions and assuming that you are invested in our growth portfolio.'}</li>
                <li className={styles.assumptionsListItem}>{'The expected income is based on an approximate 75% chance that your pension will not run out before you die.'}</li>
                <li className={styles.assumptionsListItem}>{'The Tax Free Cash stated is 25% of the estimated pensions value at retirement.'}</li>
                <li className={styles.assumptionsListItem}>{'The state pension used is the full new State Pension for the 2021/22 tax year of £179.60 per week, your actual state pension may be higher or lower than this figure.'}</li>
                <li className={styles.assumptionsListItem}>{'We have assumed a state pension age of 67. Depending on your age you may be entitled to it a year earlier or later.'}</li>
                <li className={styles.assumptionsListItem}>{'This calculator does not take account tax charges which may apply to withdrawals or to contributions that exceed your allowances. Prevailing tax rates and reliefs are dependent on your individual circumstances and are subject to change.'}</li>
                <li className={styles.assumptionsListItem}>{'Regular contributions are assumed to be personal contributions increased in line with inflation and include the tax relief associated with a basic rate taxpayer. If you are a higher or additional rate taxpayer you may be eligible for higher amounts of tax relief.'}</li>
              </ul>
            )}
          </div>
        </GlanceCardContent>
      </GlanceCard>
    </Modal>
  );
}

AssumptionsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  subHeadings: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
    }),
  ),
};

AssumptionsModal.defaultProps = {
  subHeadings: null,
};

export default AssumptionsModal;
