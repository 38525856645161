/* eslint-disable max-len */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import get from 'lodash/get';

import Modal from '../../../../components/Modal/Modal';
import GlanceCard from '../../../../components/GlanceCard/GlanceCard';
import GlanceCardContent from '../../../../components/GlanceCard/GlanceCardContent';
import ButtonLink from '../../../../components/ButtonLink/ButtonLink';

import AddMoney from '../../../../assets/images/rebranding/add-money.svg';
import closeIcon from '../../../../assets/images/rebranding/cross-icon-in-circle.svg';

import styles from '../OngoingDashboard.css';
import { getLatestCompleteContributionInstruction } from '../../../../redux/selectors/investmentAdvice';
import Button from '../../../../components/Button/Button';

const SETUP_LUMP_SUM = 'setupLumpSum';
const AMEND_CONTRIBUTION = 'amend_contribution';

const ContributionsWidget = ({
  addMoneyOnClick,
  addMoneyOptionsOnClick,
  addLumpSumOnClick,
  inProgressContributionInstructions,
  inProgressLumpSumInstructions,
  isAddPensionsAllowed,
  isCustomerOngoingMultipleRps,
  authoritativeRp,
  latestCompleteContributionInstruction,
}) => {
  const [modal, setModal] = useState({ show: false, type: null });

  const isAnyInProgressContributionInstructions = inProgressContributionInstructions.length >= 1;
  const isAnyInProgressLumpSumInstructions = inProgressLumpSumInstructions.length >= 1;

  const amendContributionDisabled = !isAddPensionsAllowed || isCustomerOngoingMultipleRps || isAnyInProgressContributionInstructions;
  const setupLumpSumDisabled = !isAddPensionsAllowed || isCustomerOngoingMultipleRps || isAnyInProgressLumpSumInstructions;
  const addMoneyDisabled = !isAddPensionsAllowed || isCustomerOngoingMultipleRps;

  const verifiedNetPersonalContribution = get(authoritativeRp, 'personalContributions.status') === 'verified' ? get(authoritativeRp, 'personalContributions.netValue') : 0;
  const completePersonalContribution = get(authoritativeRp, 'overallContributions.status') === 'active' && latestCompleteContributionInstruction ? get(latestCompleteContributionInstruction, 'regularContributionFields.amountPerMonth', 0) : 0;
  const contributionToAmend = verifiedNetPersonalContribution || completePersonalContribution;

  const verifiedNetPersonalContributionText = contributionToAmend ? `Currently £${contributionToAmend.toFixed(0).toLocaleString()}` : '';

  const WidgetModal = () => {
    let modalText = '';
    let modalBody = '';

    if (!isAddPensionsAllowed) {
      modalText = 'You need to update your pension plan before you can add regular contributions.';
    } else if (isCustomerOngoingMultipleRps) {
      modalText = 'We are currently setting up your new pension plan. You will be able to set up a contribution when this is complete.';
    } else if (modal.type === AMEND_CONTRIBUTION && isAnyInProgressContributionInstructions) {
      modalText = 'You have already requested a contribution, if you wish to make further requests, please ';
      modalBody = 'We are currently setting up your regular contribution.';
    } else if (modal.type === SETUP_LUMP_SUM && isAnyInProgressLumpSumInstructions) {
      modalText = 'If you wish to make another contribution, please ';
      modalBody = 'We are currently setting up your one-off contribution.';
    }

    return (
      <Modal show={modal.show} backDropClick={() => setModal({ show: false, type: null })} centerContent>
        <GlanceCard>
          <GlanceCardContent>
            <div className={styles.modalHeaderSection}>
              <h3 className={styles.modalHeading}>{'Add money'}</h3>
              <div className={styles.modalCloseWrapper} onClick={() => setModal({ show: false, type: null })} onKeyUp={() => {}} role="button" tabIndex="0">
                <img src={closeIcon} alt="Close icon" />
                {' '}
                <span className={styles.modalCloseWrapperText}>{'Close'}</span>
              </div>
            </div>
            <hr />
            {(!isAnyInProgressContributionInstructions && !isAnyInProgressLumpSumInstructions) ? (
              <p className={styles.modalBodyText}>{modalText}</p>
            ) : (
              <React.Fragment>
                <p className={styles.modalBodyText}>{modalBody}</p>
                <br />
                <p className={styles.modalBodyText}>
                  {modalText}
                  <ButtonLink
                    link={'/inbox/new-message'}
                    label={'contact your adviser'}
                    font="inherit"
                    displayAsHref
                  />
                  {'.'}
                </p>
              </React.Fragment>
            )}
          </GlanceCardContent>
        </GlanceCard>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      {contributionToAmend ? (
        <React.Fragment>
          <button
            type="button"
            className={classNames(styles.widget, styles.addMoneyWidget)}
            onClick={() => (!amendContributionDisabled ? addMoneyOnClick(contributionToAmend) : setModal({ show: true, type: AMEND_CONTRIBUTION }))}
          >
            <img className={styles.widgetImg} alt="amend contributions" src={AddMoney} />
            <p className={styles.widgetText}>{'Amend'}</p>
            <p className={styles.widgetText}>{'contributions'}</p>
            <p className={styles.widgetSubText}>{verifiedNetPersonalContributionText}</p>
          </button>
          <button
            type="button"
            className={classNames(styles.widget, styles.addMoneyWidget)}
            onClick={() => (!setupLumpSumDisabled ? addLumpSumOnClick(verifiedNetPersonalContribution) : setModal({ show: true, type: SETUP_LUMP_SUM }))}
          >
            <img className={styles.widgetImg} alt="Make a one-off payment" src={AddMoney} />
            <p className={styles.widgetText}>{'Make a'}</p>
            <p className={styles.widgetText}>{'one-off'}</p>
            <p className={styles.widgetText}>{'payment'}</p>
          </button>
        </React.Fragment>
      ) : (
        <button
          type="button"
          className={classNames(styles.widget, styles.addMoneyWidget)}
          onClick={() => (!addMoneyDisabled ? addMoneyOptionsOnClick(verifiedNetPersonalContribution) : setModal({ show: true, type: null }))}
        >
          <img className={styles.widgetImg} alt="add money" src={AddMoney} />
          <p className={styles.widgetText}>{'Add'}</p>
          <p className={styles.widgetText}>{'money'}</p>
        </button>
      )}
      {modal.show && <WidgetModal />}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  latestCompleteContributionInstruction: getLatestCompleteContributionInstruction(state),
});

export default connect(mapStateToProps)(ContributionsWidget);
