import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import moment from 'moment';
import classnames from 'classnames';
import { compose } from 'redux';

import sentryException from '../../../util/sentryException';
import getProfilePortfolio from '../../../util/getProfilePortfolio';
import getFundsWithRoundedSplits from '../../../util/getFundsWithRoundedSplits';
import fundMap from '../../../components/FundList/fundMap';
import AssumptionsModal from '../../../components/AssumptionsModal/AssumptionsModal';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import Button from '../../../components/Button/Button';
import Loader from '../../../components/Loader/Loader';
import NoBalanceModal from '../../../components/NoBalanceModal/NoBalanceModal';
import LegacyPlanModal from '../../../components/LegacyPlanModal/LegacyPlanModal';
import PastRetirementAgeModal from '../../../components/PastRetirementAgeModal/PastRetirementAgeModal';

import { getProjectedIncomeWithCache } from '../../../redux/modules/projection';
import { getCachedRetirementIncomePlanValues } from '../../../redux/selectors/projections';
import {
  getFirebaseUid,
  getLatestClosedEncashmentRetirementPlan,
  getAge,
  getGender,
  getIntendedRetirementAge,
  getLatestPensionValue,
  getModelDescriptor,
  getVerifiedPersonalContributionsValue,
  getAuthoritativeRetirementPlan,
  getIVUCollection,
  getVerifiedNetPersonalContributions,
  getVerifiedGrossEmployerContributions,
} from '../../../redux/selectors';
import { getIsCustomerOptoutFullEncashment, getIsCustomerOngoing, getIsCustomerOptout } from '../../../redux/selectors/experience';
import { getIsOngoingCdsAllowed } from '../../../redux/selectors/journeys';
import {
  assumptionsModalClicked,
  estimatedIncomeCalculatorClicked,
  ONGOING_ASSUMPTIONS_MODAL_CLICKED,
  ONGOING_GET_PROJECTION_DISPATCHED,
  ONGOING_GET_PROJECTION_FULFILLED,
  ONGOING_GET_PROJECTION_REJECTED,
} from '../../../redux/modules/dashboard';
import areRequiredProjectionFieldsPresent from '../../../util/areRequiredProjectionFieldsPresent';
import InformationIcon from '../../../components/InformationIcon/InformationIcon';

import IconInfoGrey from '../../../assets/images/rebranding/information-icon-in-circle-grey.svg';

import styles from './OngoingDashboard.css';

const OngoingBalance = (props) => {
  const {
    uid,
    authoritativeRp,
    gender,
    currentAge,
    retirementAge,
    estimatedPensionSavings,
    portfolio,
    getProjectionWithCache,
    cachedRetirementIncomePlanValues,
    ivuCollection,
    isOngoingCdsAllowed,
    isCustomerOptoutFullEncashment,
    latestClosedEncashmentRetirementPlan,
    dispatchAssumptionsModalClicked,
    dispatchEstimatedIncomeCalculatorClicked,
    isCustomerOptOut,
    verifiedNetPersonalContributions,
    verifiedGrossEmployerContributions,
  } = props;

  const currentCustomerData = {
    gender,
    currentAge,
    retirementAge,
    estimatedPensionSavings,
    portfolio,
    estimatedMonthlyContributions: verifiedNetPersonalContributions || 0,
    grossMonthlyContributions: verifiedGrossEmployerContributions || 0,
    statePension: false,
  };
  const requiredProjectionFieldsPresent = areRequiredProjectionFieldsPresent(
    gender,
    currentAge,
    retirementAge,
    estimatedPensionSavings,
    portfolio,
  );

  const [showAssumptions, setShowAssumptions] = useState(false);
  const [showCannotShowBalanceModal, setShowCannotShowBalanceModal] = useState(false);
  const [showPastRetirementModal, setShowPastRetirementModal] = useState(false);
  const [showLegacyPlanModal, setShowLegacyPlanModal] = useState(false);
  const [estimatedPreTaxIncomePerYear, setEstimatedPreTaxIncomePerYear] = useState(null);
  const [loadingEstimatedIncome, setLoadingEstimateIncome] = useState(true);

  const atOrPastRetirementAge = currentAge >= retirementAge;

  const pensionValue = get(authoritativeRp, 'latestPensionValue');
  const valuationDate = get(authoritativeRp, 'valuationDate');
  const modelDescriptor = get(authoritativeRp, 'modelDescriptor');
  const portfolioReference = get(authoritativeRp, 'investmentVehicleReference');
  const retirementPlanFunds = get(authoritativeRp, 'funds', []);
  const encashedRpValuationDate = get(latestClosedEncashmentRetirementPlan, 'valuationDate');
  const encashedRpValue = get(latestClosedEncashmentRetirementPlan, 'latestPensionValue');
  const legacyInvestment = !modelDescriptor;
  const optOutCancelledDate = get(authoritativeRp, 'onGoingServiceCancelledDate');
  const balanceUnavailable = typeof pensionValue !== 'number' || !valuationDate;

  const closedAccount = isCustomerOptOut && !isCustomerOptoutFullEncashment;
  const updatePensionPlan = legacyInvestment
    && !isCustomerOptoutFullEncashment
    && !isCustomerOptOut;
  const accountBeingUpdated = balanceUnavailable
    && !isCustomerOptoutFullEncashment
    && !legacyInvestment
    && !isCustomerOptOut;
  const pastRetirement = !legacyInvestment
    && !balanceUnavailable
    && atOrPastRetirementAge
    && !isCustomerOptoutFullEncashment
    && !isCustomerOptOut;
  const showIncreaseEstimatedIncome = !legacyInvestment
    && !balanceUnavailable
    && !atOrPastRetirementAge
    && !isCustomerOptOut;
  const shouldRenderAssumptionsModal = !legacyInvestment
    && !balanceUnavailable
    && !atOrPastRetirementAge;
  const smallerStyling = closedAccount
    || updatePensionPlan
    || accountBeingUpdated
    || pastRetirement;

  const funds = getFundsWithRoundedSplits(retirementPlanFunds);
  const mappedFunds = funds.filter((fund) => fund.name !== 'Cash').map((fund) => {
    const fundData = fundMap[fund.isin];
    if (!fundData) return;
    return { name: fundData.name, fundSplit: get(fund, 'fundRoundedSplit'), isin: fund.isin };
  });

  const { planName, esg } = getProfilePortfolio(ivuCollection, portfolioReference, mappedFunds);
  const planDisplayName = esg ? `${planName} ESG` : planName;

  const getBalance = (canShowBalance, value) => {
    return canShowBalance ? `£${Number(value.toFixed(0)).toLocaleString()}` : '£ -';
  };

  const getEstimatedIncome = (dontShowEstimatedIncome) => {
    if (dontShowEstimatedIncome) return '£ - ';
    if (loadingEstimatedIncome) {
      return <Loader />;
    }

    return estimatedPreTaxIncomePerYear ? `£${Number(estimatedPreTaxIncomePerYear.toFixed(0)).toLocaleString()} / Year` : '£ -';
  };

  const getSubHeadings = () => {
    const subHeadings = [
      {
        title: 'Your pension plan',
        content: planDisplayName,
      },
    ];
    if (retirementAge) {
      subHeadings.push({
        title: 'Your planned retirement age',
        content: retirementAge.toString(),
      });
    }
    if (verifiedNetPersonalContributions) {
      subHeadings.push({
        title: 'Monthly personal contribution',
        content: `£${Number(verifiedNetPersonalContributions.toFixed(0)).toLocaleString()}`,
      });
    }
    if (verifiedGrossEmployerContributions) {
      subHeadings.push({
        title: 'Monthly employer contribution',
        content: `£${Number(verifiedGrossEmployerContributions.toFixed(0)).toLocaleString()}`,
      });
    }
    return subHeadings;
  };

  useEffect(() => {
    const estimatedIncome = async () => {
      try {
        setLoadingEstimateIncome(true);
        if (requiredProjectionFieldsPresent) {
          const cachedValues = cachedRetirementIncomePlanValues;
          const currentProjection = await getProjectionWithCache(
            uid,
            currentCustomerData,
            cachedValues,
          );
          if (currentProjection && currentProjection.estimatedPreTaxIncomePerYear) {
            setEstimatedPreTaxIncomePerYear(currentProjection.estimatedPreTaxIncomePerYear);
          }
        }
      } catch (error) {
        sentryException(error, {
          section: 'ongoing-balance-estimate-income',
        });
      }

      setLoadingEstimateIncome(false);
    };

    estimatedIncome();
  }, []);

  return (
    <div className={styles.ongoingBalanceCircle}>
      <div className={classnames(styles.balance, { [styles.balanceSmall]: smallerStyling })}>
        <p className={styles.body}>{'Your balance'}</p>
        {!balanceUnavailable && !isCustomerOptoutFullEncashment && !isCustomerOptOut && (
          <p className={styles.date}>{`as of ${moment(valuationDate).utc().format('DD/MM/YY')}`}</p>
        )}
        {isCustomerOptoutFullEncashment
          && encashedRpValuationDate
          && encashedRpValue
          && !isCustomerOptOut
          && (
            <p className={styles.date}>{`updated on ${moment(encashedRpValuationDate).utc().format('DD/MM/YY')}`}</p>
          )}
        <p
          className={
            classnames(
              styles.value,
              {
                [styles.noBalance]: balanceUnavailable
                  || (isCustomerOptoutFullEncashment && !encashedRpValue),
              },
            )
          }
        >
          {!isCustomerOptoutFullEncashment && (
            getBalance(!balanceUnavailable && !isCustomerOptOut, pensionValue)
          )}
          {isCustomerOptoutFullEncashment && (
            getBalance(encashedRpValue, encashedRpValue)
          )}
        </p>
      </div>
      {!isCustomerOptoutFullEncashment && !isCustomerOptOut && (
        <div className={classnames(
          styles.estimatedIncome,
          { [styles.estimatedIncomeSmall]: smallerStyling },
        )}
        >
          <div style={{ display: 'flex' }}>
            <p className={styles.body}>{'Estimated income'}</p>
            {pastRetirement && (
              <div className={styles.tooltipSpacing}>
                <React.Fragment>
                  <InformationIcon
                    onClick={() => setShowPastRetirementModal(!showPastRetirementModal)}
                    infoIcon={IconInfoGrey}
                  />
                  <PastRetirementAgeModal
                    retirementAge={retirementAge}
                    onClose={() => setShowPastRetirementModal(!showPastRetirementModal)}
                    show={showPastRetirementModal}
                  />
                </React.Fragment>
              </div>
            )}
          </div>
          <p
            className={
              classnames(
                styles.value,
                { [styles.noBalance]: legacyInvestment || balanceUnavailable },
              )
            }
          >
            {getEstimatedIncome(legacyInvestment || balanceUnavailable || atOrPastRetirementAge)}
          </p>
        </div>
      )}
      {!legacyInvestment
        && !balanceUnavailable
        && !atOrPastRetirementAge
        && !isCustomerOptoutFullEncashment
        && !isCustomerOptOut && (
          <ButtonLink
            font="bodyTwo"
            variant="primary"
            label="How is it estimated?"
            onClick={() => {
              dispatchAssumptionsModalClicked();
              setShowAssumptions(true);
            }}
          />
      )}
      {accountBeingUpdated && (
        <React.Fragment>
          <p className={styles.canShowBalanceHeading}>{'Welcome to Profile Pensions, your account is being updated'}</p>
          <Button
            size="small"
            label="Why we cannot show your latest balance"
            onClick={() => setShowCannotShowBalanceModal(true)}
          />
          <NoBalanceModal
            show={showCannotShowBalanceModal}
            onClose={() => setShowCannotShowBalanceModal(false)}
          />
        </React.Fragment>
      )}
      {updatePensionPlan && (
        <React.Fragment>
          <p className={styles.canShowBalanceHeading}>
            {
              `In order to view your
              ${balanceUnavailable ? ' balance and ' : ' '}
              estimated income you will need to update your pension plan`
            }
          </p>
          <Button
            size="small"
            label="Update your plan"
            onClick={() => setShowLegacyPlanModal(true)}
          />
          <LegacyPlanModal
            isOngoingCdsAllowed={isOngoingCdsAllowed}
            show={showLegacyPlanModal}
            onClose={() => setShowLegacyPlanModal(false)}
          />
        </React.Fragment>
      )}
      {closedAccount && (
        <React.Fragment>
          <p className={styles.canShowBalanceHeading}>
            {
              `You closed your account
              ${optOutCancelledDate ? ` on ${moment(optOutCancelledDate).utc().format('DD/MM/YY')}, to ` : '. To'}
              reactivate it, please message your Pension Adviser`
            }
          </p>
          <Button
            size="small"
            label="Reactivate my account"
            to="/inbox/new-message"
          />
        </React.Fragment>
      )}

      {isCustomerOptoutFullEncashment && (
        <React.Fragment>
          <p className={styles.canShowBalanceHeading}>
            {`You have requested full encashment of your pension on ${latestClosedEncashmentRetirementPlan.onGoingServiceCancelledDate ? (
              `on ${moment(latestClosedEncashmentRetirementPlan.onGoingServiceCancelledDate).format('DD/MM/YYYY')}`
            ) : ''}
            `}
          </p>
          <p className={styles.encashmentSubheading}>
            {'It can take up to 6 weeks for your money to arrive. Your balance might not be updated whilst this transaction is being processed'}
          </p>
        </React.Fragment>
      )}
      {(showIncreaseEstimatedIncome) && (
        <Button
          size="medium"
          className={styles.ctaButton}
          to="/estimated-income-calculator"
          onClick={dispatchEstimatedIncomeCalculatorClicked}
          label={
            <span>{'Increase your estimated income'}</span>
          }
        />
      )}
      {shouldRenderAssumptionsModal && showAssumptions && (
        <AssumptionsModal
          show={showAssumptions}
          onClose={() => setShowAssumptions(false)}
          subHeadings={getSubHeadings()}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
  authoritativeRp: getAuthoritativeRetirementPlan(state),
  isCustomerOngoing: getIsCustomerOngoing(state),
  gender: getGender(state),
  currentAge: getAge(state),
  retirementAge: getIntendedRetirementAge(state),
  estimatedPensionSavings: getLatestPensionValue(state),
  portfolio: getModelDescriptor(state),
  verifiedMonthlyContributions: getVerifiedPersonalContributionsValue(state),
  verifiedNetPersonalContributions: getVerifiedNetPersonalContributions(state),
  verifiedGrossEmployerContributions: getVerifiedGrossEmployerContributions(state),
  ivuCollection: getIVUCollection(state),
  isOngoingCdsAllowed: getIsOngoingCdsAllowed(state),
  isCustomerOptoutFullEncashment: getIsCustomerOptoutFullEncashment(state),
  latestClosedEncashmentRetirementPlan: getLatestClosedEncashmentRetirementPlan(state),
  isCustomerOptOut: getIsCustomerOptout(state),
  cachedRetirementIncomePlanValues: getCachedRetirementIncomePlanValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  getProjectionWithCache: (uid, data, cachedValues) => dispatch(
    getProjectedIncomeWithCache(uid, data, cachedValues, {
      DISPATCHED: ONGOING_GET_PROJECTION_DISPATCHED,
      FULFILLED: ONGOING_GET_PROJECTION_FULFILLED,
      REJECTED: ONGOING_GET_PROJECTION_REJECTED,
    }),
  ),
  dispatchAssumptionsModalClicked: () => dispatch(
    assumptionsModalClicked(ONGOING_ASSUMPTIONS_MODAL_CLICKED),
  ),
  dispatchEstimatedIncomeCalculatorClicked: () => dispatch(
    estimatedIncomeCalculatorClicked(),
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(OngoingBalance);
