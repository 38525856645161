import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import styles from './RetirementIncomePage.css';
import {
  getMonthlyContributionsCopy,
  getTransferPensionCopy,
  getAddLumpSumCopy,
  getRetirementAgeCopy,
  ChangedScenario,
  UnchangedScenario,
  ChangedScenarioTaskCard,
} from './Utils';
import { currencyString } from '../../util/currency';

import healthyPlantImg from '../../assets/images/retirementIncome/healthy-plant.svg';
import dyingPlantImg from '../../assets/images/retirementIncome/dying-plant.svg';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import AssumptionsModal from '../../components/AssumptionsModal/AssumptionsModal';
import Button from '../../components/Button/Button';

import transferPensionIcon from '../../assets/images/rebranding/tri-tone-overlapping-circles.svg';
import addPound from '../../assets/images/rebranding/add-money.svg';
import changeRetirementAgeIcon from '../../assets/images/retirementIncome/edit-retirement-age.svg';
import {
  getIntendedRetirementAge,
  getVerifiedGrossEmployerContributions,
  getVerifiedNetPersonalContributions,
} from '../../redux/selectors';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import {
  resultsStepTryDifferentScenarioButtonClickedAction,
  resultsStepAddPensionButtonClickedAction,
  resultsStepSetupPaymentsButtonClickedAction,
  resultsStepMessageAdvisorButtonClickedAction,
  resultsStepViewAssumptionsClickedAction,
  resultsStepAddLumpSumMessageAdvisorButtonClickedAction,
  resultsStepChangeRetirementAgeMessageAdvisorButtonClickedAction,
  resultsStepHasInProgressInstructionSetupPaymentsButtonClickedAction,
  resultsStepLumpSumPaymentButtonClickedAction,
  resultsStepPageLanded,
} from '../../redux/modules/projection';
import { getHasInProgressContributionInstructions, getHasInProgressLumpSumInstructions } from '../../redux/selectors/investmentAdvice';

function RetirementIncomeResultsStep({
  state,
  currentVerifiedContributionsValue,
  intendedRetirementAge,
  currentProjectedAnnualIncome,
  newProjectedAnnualIncome,
  hasInProgressContributions,
  hasInProgressLumpSumInstructions,
  navigateToCreateScenario,
  dispatchResultsStepViewAssumptionsClicked,
  dispatchResultsStepTryDifferentScenarioButtonClicked,
  dispatchResultsStepAddPensionButtonClicked,
  dispatchResultsStepSetupPaymentsButtonClicked,
  dispatchResultsStepLumpSumPaymentButtonClicked,
  dispatchResultsStepMessageAdvisorButtonClicked,
  dispatchResultsStepChangeRetirementAgeMessageAdvisorButtonClicked,
  dispatchResultsStepAddLumpSumMessageAdvisorButtonClicked,
  dispatchResultsStepHasInProgressInstructionSetupPaymentsButtonClicked,
  verifiedGrossEmployerContributions,
  dispatchResultsStepPageLanded,
}) {
  const [showModal, setShowModal] = useState(false);

  const { scenarios } = state;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchResultsStepPageLanded();
  }, []);

  const annualIncomeDifference = newProjectedAnnualIncome - currentProjectedAnnualIncome;
  const absoluteAnnualIncomeDifference = Math.abs(annualIncomeDifference);
  const incomeIncrease = annualIncomeDifference >= 0;

  const resultTaskOrder = [
    'transferPension',
    'monthlyContributions',
    'addLumpSum',
    'changeRetirementAge',
  ];

  const showUnchangedSection = Object.keys(scenarios).filter((key) => !get(scenarios, `[${key}].enabled`)).length > 0;
  const lastEnabledScenarioName = resultTaskOrder.filter((key) => get(scenarios, `[${key}].enabled`)).pop();
  const firstEnabledScenarioName = resultTaskOrder.filter((key) => get(scenarios, `[${key}].enabled`)).shift();

  const assumptionsSubheadings = [];

  const personalContributionsValue = scenarios.monthlyContributions.enabled
    ? scenarios.monthlyContributions.value : currentVerifiedContributionsValue;

  const retirementAgeValue = scenarios.changeRetirementAge.enabled
    ? scenarios.changeRetirementAge.value : intendedRetirementAge;

  if (retirementAgeValue) {
    assumptionsSubheadings.push({
      title: 'Your planned retirement age',
      content: retirementAgeValue,
    });
  }
  if (personalContributionsValue) {
    assumptionsSubheadings.push({
      title: 'Monthly personal contribution',
      content: `£${Number(personalContributionsValue.toFixed(0)).toLocaleString()}`,
    });
  }
  if (verifiedGrossEmployerContributions) {
    assumptionsSubheadings.push({
      title: 'Monthly employer contribution',
      content: `£${Number(verifiedGrossEmployerContributions.toFixed(0)).toLocaleString()}`,
    });
  }

  return (
    <React.Fragment>
      <div className={classnames(styles.roundedDiv, styles.roundedDivResults)}>
        <div className={styles.roundedDivHeading}>
          <h1 className={styles.resultsHeading}>{'Your results'}</h1>
          <p className={styles.resultsSubtext}>
            {'Your estimated income'}
            <br />
            {' would '}
            <span
              className={classnames({
                [styles.increaseText]: incomeIncrease,
                [styles.decreaseText]: !incomeIncrease,
              })}
            >
              {incomeIncrease ? 'increase by' : 'decrease by'}
              {' '}
              <span className={styles.monetaryValue}>{`${currencyString(absoluteAnnualIncomeDifference)}`}</span>
              {' / year'}
            </span>
          </p>
          <img
            src={incomeIncrease ? healthyPlantImg : dyingPlantImg}
            alt={incomeIncrease ? 'Healthy plant' : 'Dying plant'}
          />
        </div>
        <div className={classnames(styles.incomeTable, {
          [styles.increaseBorder]: incomeIncrease,
          [styles.decreaseBorder]: !incomeIncrease,
        })}
        >
          <div className={styles.incomeTableRow}>
            <div className={styles.incomeTableCell} />
            <div className={classnames(styles.incomeTableCell, styles.incomeTableHeader)}>
              <span>{'Before'}</span>
            </div>
            <div className={classnames(styles.incomeTableCell, styles.incomeTableHeader)}>
              <span>
                {'After'}
              </span>
            </div>
          </div>
          <div className={styles.incomeTableRow}>
            <div className={classnames(styles.incomeTableCell, styles.incomeTableCellLeftAlign)}>
              <span>{'Projected income / year'}</span>

            </div>
            <div className={classnames(styles.incomeTableCell, styles.incomeTableMonetaryValues)}>
              <span>{`${currencyString(currentProjectedAnnualIncome)}*`}</span>
            </div>
            <div className={classnames(styles.incomeTableCell, styles.incomeTableMonetaryValues)}>
              <span>
                {`${currencyString(newProjectedAnnualIncome)}*`}
              </span>
            </div>
          </div>
          <div className={styles.incomeTableRow}>
            <div className={classnames(styles.incomeTableCell, styles.incomeTableCellLeftAlign)}><span>{'Difference'}</span></div>
            <div className={classnames(styles.incomeTableCell, styles.incomeTableMonetaryValues)} />
            <div className={classnames(
              styles.incomeTableCell,
              styles.incomeTableMonetaryValues,
              styles.incomeTableMonetaryValuesBottomCell,
              {
                [styles.increaseText]: incomeIncrease,
                [styles.decreaseText]: !incomeIncrease,
              },
            )}
            >
              <span>
                {`${incomeIncrease ? '+' : '-'}${currencyString(absoluteAnnualIncomeDifference)}*`}
              </span>
            </div>
          </div>
        </div>
        <ButtonLink
          label={'How is it estimated?'}
          variant="primary"
          fontSize={14}
          font="bodyOne"
          onClick={() => {
            dispatchResultsStepViewAssumptionsClicked();
            setShowModal(true);
          }}
        />

        <p className={styles.resultsPageTextForest}>{'Based on your selections'}</p>
        {scenarios.monthlyContributions.enabled && (
          <ChangedScenario
            title={currentVerifiedContributionsValue > 0 ? 'Amend my contributions' : 'Set up regular contributions'}
            icon={addPound}
            getContent={getMonthlyContributionsCopy}
            functionProps={[
              true, scenarios.monthlyContributions.value, currentVerifiedContributionsValue,
            ]}
          />
        )}

        {scenarios.transferPension.enabled && (
          <ChangedScenario
            title={'Transfer a pension'}
            icon={transferPensionIcon}
            getContent={getTransferPensionCopy}
            functionProps={[true, scenarios.transferPension.value]}
          />
        )}

        {scenarios.addLumpSum.enabled && (
          <ChangedScenario
            title={'Add a one-off contribution'}
            icon={addPound}
            getContent={getAddLumpSumCopy}
            functionProps={[true, scenarios.addLumpSum.value]}
          />
        )}

        {scenarios.changeRetirementAge.enabled && (
          <ChangedScenario
            title={'Change my retirement age'}
            icon={changeRetirementAgeIcon}
            getContent={getRetirementAgeCopy}
            functionProps={[true, scenarios.changeRetirementAge.value, intendedRetirementAge]}
          />
        )}

        {showUnchangedSection && (
          <React.Fragment>
            <p className={styles.resultsPageTextForest}>{'Things you didn\'t change'}</p>
            <ul className={styles.noChangesList} style={{ paddingInlineStart: '20px' }}>
              {!scenarios.monthlyContributions.enabled && (
                <UnchangedScenario
                  getContent={getMonthlyContributionsCopy}
                  functionProps={[false, null, currentVerifiedContributionsValue]}
                />
              )}
              {!scenarios.transferPension.enabled && (
                <UnchangedScenario getContent={getTransferPensionCopy} functionProps={[false]} />
              )}
              {!scenarios.addLumpSum.enabled && (
                <UnchangedScenario getContent={getAddLumpSumCopy} functionProps={[false]} />
              )}
              {!scenarios.changeRetirementAge.enabled && (
                <UnchangedScenario
                  getContent={getRetirementAgeCopy}
                  functionProps={[false, null, intendedRetirementAge]}
                />
              )}
            </ul>
          </React.Fragment>
        )}
        <hr />
        <p className={styles.resultsPageFooterText}>{'You can use the tool whenever you like, your previous result will automatically be saved for your reference.'}</p>
        <div className={styles.ctaButton}>
          <Button
            size="large"
            label="Try a different scenario"
            fullWidth
            onClick={() => {
              dispatchResultsStepTryDifferentScenarioButtonClicked();
              navigateToCreateScenario();
            }}
          />
        </div>

        <AssumptionsModal
          show={showModal}
          onClose={() => setShowModal(false)}
          subHeadings={assumptionsSubheadings}
        />
      </div>
      <div className={styles.resultsPageLowerSection}>
        <h3 className={styles.resultsPageSelectOptionText}>
          {'Complete the tasks below to make your changes'}
        </h3>
        <div className={classnames(styles.resultsPageSelectOptionCards, {
          [styles.resultsPageSelectOptionCardsHideSideBar]:
            (firstEnabledScenarioName === lastEnabledScenarioName),
        })}
        >
          <ChangedScenarioTaskCard
            enabled={scenarios.transferPension.enabled}
            title={'Find & Combine old pensions'}
            icon={transferPensionIcon}
            linkTo={'/add-pensions'}
            onClick={dispatchResultsStepAddPensionButtonClicked}
            isFirstRow={firstEnabledScenarioName === 'transferPension'}
            isLastRow={lastEnabledScenarioName === 'transferPension'}
          />

          <ChangedScenarioTaskCard
            enabled={scenarios.monthlyContributions.enabled}
            title={'Set up regular contributions'}
            icon={addPound}
            onClick={dispatchResultsStepSetupPaymentsButtonClicked}
            linkTo={!hasInProgressContributions && `/setup-contribution?amount=${scenarios.monthlyContributions.value}`}
            isFirstRow={firstEnabledScenarioName === 'monthlyContributions'}
            isLastRow={lastEnabledScenarioName === 'monthlyContributions'}
          >
            {hasInProgressContributions && (
              <p className={styles.completeScenarioTaskBodyText}>
                {'We are currently setting up a contribution you have requested. If you wish to make further requests please '}
                <ButtonLink
                  variant="primary"
                  to="/inbox/new-message"
                  label="message your Pension Adviser"
                  onClick={dispatchResultsStepHasInProgressInstructionSetupPaymentsButtonClicked}
                  font="bodyOne"
                  displayAsHref
                />
                {'.'}
              </p>
            )}
          </ChangedScenarioTaskCard>
          <ChangedScenarioTaskCard
            enabled={scenarios.addLumpSum.enabled}
            title={'Add a one-off contribution'}
            icon={addPound}
            onClick={dispatchResultsStepLumpSumPaymentButtonClicked}
            linkTo={!hasInProgressLumpSumInstructions && `/setup-lump-sum?amount=${scenarios.addLumpSum.value}`}
            isFirstRow={firstEnabledScenarioName === 'addLumpSum'}
            isLastRow={lastEnabledScenarioName === 'addLumpSum'}
          >
            {hasInProgressLumpSumInstructions && (
              <p className={styles.completeScenarioTaskBodyText}>
                {'We are currently setting up a contribution you have requested. If you wish to make further requests please '}
                <ButtonLink
                  variant="primary"
                  to="/inbox/new-message"
                  label="message your Pension Adviser"
                  onClick={dispatchResultsStepAddLumpSumMessageAdvisorButtonClicked}
                  font="bodyOne"
                  displayAsHref
                />
                {'.'}
              </p>
            )}
          </ChangedScenarioTaskCard>
          <ChangedScenarioTaskCard
            enabled={scenarios.changeRetirementAge.enabled}
            title={'Change your retirement age'}
            icon={changeRetirementAgeIcon}
            isFirstRow={firstEnabledScenarioName === 'changeRetirementAge'}
            isLastRow={lastEnabledScenarioName === 'changeRetirementAge'}
          >
            <p className={styles.completeScenarioTaskBodyText}>
              <ButtonLink
                variant="primary"
                label="Message your Pension Adviser"
                to="/inbox/new-message"
                font="bodyOne"
                onClick={dispatchResultsStepChangeRetirementAgeMessageAdvisorButtonClicked}
                displayAsHref
              />
              {' if you have any questions around changing your retirement age.'}
            </p>
          </ChangedScenarioTaskCard>
        </div>
        <div className={styles.resultPageContactUsWrapper}>
          <ContactUsContent
            showButton={false}
            customMessage={(
              <p
                className={styles.resultPageContactUsMessage}
              >
                {'Your dedicated Pension Adviser is on hand to help. If you have any questions, please feel free to '}
                <ButtonLink
                  label="message me"
                  variant="primary"
                  to="/inbox/new-message"
                  displayAsHref
                  font="bodyOne"
                  onClick={dispatchResultsStepMessageAdvisorButtonClicked}
                />
                {'.'}
              </p>
            )}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

RetirementIncomeResultsStep.propTypes = {};

const mapStateToProps = (state) => ({
  currentVerifiedContributionsValue: getVerifiedNetPersonalContributions(state),
  intendedRetirementAge: getIntendedRetirementAge(state),
  hasInProgressContributions: getHasInProgressContributionInstructions(state),
  hasInProgressLumpSumInstructions: getHasInProgressLumpSumInstructions(state),
  verifiedGrossEmployerContributions: getVerifiedGrossEmployerContributions(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchResultsStepViewAssumptionsClicked:
    () => dispatch(resultsStepViewAssumptionsClickedAction()),
  dispatchResultsStepTryDifferentScenarioButtonClicked:
    () => dispatch(resultsStepTryDifferentScenarioButtonClickedAction()),
  dispatchResultsStepAddPensionButtonClicked:
    () => dispatch(resultsStepAddPensionButtonClickedAction()),
  dispatchResultsStepSetupPaymentsButtonClicked:
    () => dispatch(resultsStepSetupPaymentsButtonClickedAction()),
  dispatchResultsStepLumpSumPaymentButtonClicked:
    () => dispatch(resultsStepLumpSumPaymentButtonClickedAction()),
  dispatchResultsStepMessageAdvisorButtonClicked:
    () => dispatch(resultsStepMessageAdvisorButtonClickedAction()),
  dispatchResultsStepChangeRetirementAgeMessageAdvisorButtonClicked:
    () => dispatch(resultsStepChangeRetirementAgeMessageAdvisorButtonClickedAction()),
  dispatchResultsStepAddLumpSumMessageAdvisorButtonClicked:
    () => dispatch(resultsStepAddLumpSumMessageAdvisorButtonClickedAction()),
  dispatchResultsStepHasInProgressInstructionSetupPaymentsButtonClicked:
    () => dispatch(resultsStepHasInProgressInstructionSetupPaymentsButtonClickedAction()),
  dispatchResultsStepPageLanded: () => dispatch(resultsStepPageLanded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RetirementIncomeResultsStep);
