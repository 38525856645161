import React, { useState } from 'react';
import classNames from 'classnames';

import Modal from '../../../../components/Modal/Modal';
import GlanceCard from '../../../../components/GlanceCard/GlanceCard';
import GlanceCardContent from '../../../../components/GlanceCard/GlanceCardContent';

import closeIcon from '../../../../assets/images/rebranding/cross-icon-in-circle.svg';
import AddPensions from '../../../../assets/images/rebranding/tri-tone-overlapping-circles.svg';

import styles from '../OngoingDashboard.css';

const AddPensionsWidget = ({
  onClick: addPensionsLink,
  isCustomerOngoingMultipleRps,
  isCustomerOptoutFullEncashment,
  isAddPensionsAllowed,
}) => {
  const [showModal, setShowModal] = useState(false);

  const disabled = isCustomerOngoingMultipleRps
    || isCustomerOptoutFullEncashment
    || !isAddPensionsAllowed;

  const WidgetModal = () => {
    let modalText = '';

    if (isCustomerOngoingMultipleRps) {
      modalText = 'We are currently setting up your new pension plan. You will be able to add or transfer more pensions when this is complete.';
    } else if (!isAddPensionsAllowed) {
      modalText = 'You need to update your pension plan before you can transfer another policy.';
    }

    if (isCustomerOptoutFullEncashment) {
      return null;
    }

    return (
      <Modal show={showModal} backDropClick={() => setShowModal(false)} centerContent>
        <GlanceCard>
          <GlanceCardContent>
            <div className={styles.modalHeaderSection}>
              <h3 className={styles.modalHeading}>{'Add pensions'}</h3>
              <div className={styles.modalCloseWrapper} onClick={() => setShowModal(false)} onKeyUp={() => {}} role="button" tabIndex="0">
                <img src={closeIcon} alt="Close icon" />
                {' '}
                <span className={styles.modalCloseWrapperText}>{'Close'}</span>
              </div>
            </div>
            <hr />
            <p className={styles.modalBodyText}>{modalText}</p>
          </GlanceCardContent>
        </GlanceCard>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      <button
        type="button"
        className={classNames(styles.widget, styles.addPensionsWidget)}
        onClick={() => (!disabled ? addPensionsLink() : setShowModal(true))}
      >
        <img className={styles.widgetImg} alt="add pensions" src={AddPensions} />
        <p className={styles.widgetText}>{'Add pensions'}</p>
      </button>
      {showModal && <WidgetModal />}
    </React.Fragment>
  );
};

export default AddPensionsWidget;
