import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';

import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import AssumptionsModal from '../../components/AssumptionsModal/AssumptionsModal';
import Loader from '../../components/Loader/Loader';
import fundMap from '../../components/FundList/fundMap';
import NoBalanceModal from '../../components/NoBalanceModal/NoBalanceModal';
import sentryException from '../../util/sentryException';
import getProfilePortfolio from '../../util/getProfilePortfolio';
import { getActivityPlanInstructions } from '../../redux/selectors/investmentAdvice';
import getFundsWithRoundedSplits from '../../util/getFundsWithRoundedSplits';
import PlanActivityCurrentPlan from './sections/PlanActivityCurrentPlan';
import PlanActivityPensionRows from './sections/PlanActivityPensionRows';
import PlanActivityInstructions from './sections/PlanActivityInstructions';

import {
  getAge,
  getGender,
  getIntendedRetirementAge,
  getLatestPensionValue,
  getModelDescriptor,
  getVerifiedPersonalContributionsValue,
  getAuthoritativeRetirementPlan,
  getIVUCollection,
  getFirebaseUid,
  getVerifiedNetPersonalContributions,
  getVerifiedGrossEmployerContributions,
} from '../../redux/selectors';

import { getNotificationTracking, getHasViewedPlanActivity } from '../../redux/selectors/notificationTracking';
import { getProjectedIncomeWithCache } from '../../redux/modules/projection';
import { getCachedRetirementIncomePlanValues } from '../../redux/selectors/projections';
import { planActivityViewedTracking } from '../../redux/modules/notificationTracking';
import {
  assumptionsModalClicked,
  planActivityPageLoaded,
  PLAN_ACTIVITY_ASSUMPTIONS_MODAL_CLICKED,
  PLAN_ACTIVITY_GET_PROJECTION_DISPATCHED,
  PLAN_ACTIVITY_GET_PROJECTION_FULFILLED,
  PLAN_ACTIVITY_GET_PROJECTION_REJECTED,
} from '../../redux/modules/dashboard';

import PoundSign from '../../assets/images/pound-sign.svg';

import areRequiredProjectionFieldsPresent from '../../util/areRequiredProjectionFieldsPresent';

import styles from './PlanActivityPage.css';

const PlanActivityPage = ({
  gender,
  currentAge,
  retirementAge,
  estimatedPensionSavings,
  portfolio,
  getProjectionWithCache,
  cachedRetirementIncomePlanValues,
  authoritativeRp,
  ivuCollection,
  uid,
  hasViewedPlanActivity,
  activityPlanInstructions,
  dispatchAssumptionsModalClicked,
  dispatchPlanActivityPageLoaded,
  verifiedNetPersonalContributions,
  verifiedGrossEmployerContributions,
}) => {
  useEffect(() => {
    dispatchPlanActivityPageLoaded();

    const href = window.location.href.substring(
      window.location.href.lastIndexOf('#') + 1,
    );
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  const instructions = [...activityPlanInstructions];
  const [showAssumptions, setShowAssumptions] = useState(false);
  const [projections, setProjections] = useState({
    estimatedPensionValueAtRetirement: null,
    estimatedPreTaxIncomePerYear: null,
    estimatedTaxFreeCashAmount: null,
    isModelDescriptorSupported: null,
    netRealReturn: null,
  });
  const [loadingEstimatedIncome, setLoadingEstimateIncome] = useState(true);
  const [hasPreviouslyViewedPlanActivity] = useState(hasViewedPlanActivity);
  const [showCannotShowBalanceModal, setShowCannotShowBalanceModal] = useState(false);

  const pensionValue = get(authoritativeRp, 'latestPensionValue');
  const valuationDate = get(authoritativeRp, 'valuationDate');
  const portfolioReference = get(authoritativeRp, 'investmentVehicleReference');
  const retirementPlanFunds = get(authoritativeRp, 'funds', []);

  const funds = getFundsWithRoundedSplits(retirementPlanFunds);
  const mappedFunds = funds.filter((fund) => fund.name !== 'Cash').map((fund) => {
    const fundData = fundMap[fund.isin];
    if (!fundData) return;
    return { name: fundData.name, fundSplit: get(fund, 'fundRoundedSplit'), isin: fund.isin };
  });

  const { planName, esg } = getProfilePortfolio(ivuCollection, portfolioReference, mappedFunds);
  const planDisplayName = esg ? `${planName} ESG` : planName;

  const parsedValuationDate = valuationDate ? moment(valuationDate).format('DD/MM/YY') : null;
  const showBalance = Boolean(pensionValue && parsedValuationDate);

  const currentCustomerData = {
    gender,
    currentAge,
    retirementAge,
    estimatedPensionSavings,
    portfolio,
    statePension: false,
    estimatedMonthlyContributions: verifiedNetPersonalContributions || 0,
    grossMonthlyContributions: verifiedGrossEmployerContributions || 0,
  };
  const requiredProjectionFieldsPresent = areRequiredProjectionFieldsPresent(
    gender,
    currentAge,
    retirementAge,
    estimatedPensionSavings,
    portfolio,
  );

  useEffect(() => {
    const estimatedIncome = async () => {
      try {
        setLoadingEstimateIncome(true);
        if (requiredProjectionFieldsPresent) {
          const cachedValues = cachedRetirementIncomePlanValues;
          const currentProjection = await getProjectionWithCache(
            uid,
            currentCustomerData,
            cachedValues,
          );
          setProjections({ ...currentProjection });
        }
      } catch (error) {
        sentryException(error, {
          section: 'ongoing-balance-estimate-income',
        });
      }

      setLoadingEstimateIncome(false);
    };

    estimatedIncome();
  }, []);

  useEffect(() => {
    if (!hasPreviouslyViewedPlanActivity) {
      planActivityViewedTracking(uid);
    }
  }, []);

  const getValue = (value, time) => {
    if (loadingEstimatedIncome) {
      return <Loader />;
    }

    if (time) {
      return value ? `£${Number(value.toFixed(0)).toLocaleString()} / ${time}` : '£ -';
    }

    return value ? `£${Number(value.toFixed(0)).toLocaleString()}` : '£ -';
  };

  const getBalance = (_showBalance) => {
    return _showBalance ? `£${Number(pensionValue.toFixed(0)).toLocaleString()}` : '£ -';
  };

  const getSubHeadings = () => {
    const subHeadings = [
      {
        title: 'Your pension plan',
        content: planDisplayName,
      },
    ];
    if (retirementAge) {
      subHeadings.push({
        title: 'Your planned retirement age',
        content: retirementAge,
      });
    }
    if (verifiedNetPersonalContributions) {
      subHeadings.push({
        title: 'Monthly personal contribution',
        content: `£${Number(verifiedNetPersonalContributions.toFixed(0)).toLocaleString()}`,
      });
    }
    if (verifiedGrossEmployerContributions) {
      subHeadings.push({
        title: 'Monthly employer contribution',
        content: `£${Number(verifiedGrossEmployerContributions.toFixed(0)).toLocaleString()}`,
      });
    }
    return subHeadings;
  };

  return (
    <BaseGrid>
      <div className={styles.backButton}>
        <ButtonLink
          secondary
          mid
          label="← Back"
          to="/"
        />
      </div>
      <div className={styles.currentPlan}>
        <div className={styles.container}>
          <PlanActivityCurrentPlan
            hasPreviouslyViewedPlanActivity={hasPreviouslyViewedPlanActivity}
          />
        </div>
      </div>
      <div className={styles.planActivityCard}>
        <div className={styles.container}>
          <h3 className={styles.heading}>{'Plan activity'}</h3>
          <div className={styles.valuesWrapper}>
            <div className={styles.valuesIcon}>
              <div className={styles.poundSignContainer}>
                <img className={styles.poundSign} alt="pound sign" src={PoundSign} />
              </div>
            </div>
            <div className={styles.valuesContainer}>
              <div className={styles.balanceContainer}>
                <p className={styles.darkText}>{`My balance of ${getBalance(showBalance)}`}</p>
                <p className={styles.lightText}>{parsedValuationDate ? `Updated on ${parsedValuationDate}` : 'Updated on -'}</p>
              </div>
              {showBalance && (
                <div className={styles.estimatedValuesContainer}>
                  <div className={styles.estimatedValue}>
                    <p className={styles.lightText}>{'Estimated value at retirement'}</p>
                    <p className={styles.darkText}>
                      {getValue(projections.estimatedPensionValueAtRetirement)}
                    </p>
                  </div>
                  <div className={styles.estimatedValue}>
                    <p className={styles.lightText}>{'Estimated annual drawdown'}</p>
                    <p className={styles.darkText}>
                      {getValue(projections.estimatedPreTaxIncomePerYear, 'Year')}
                    </p>
                  </div>
                  <div className={styles.estimatedValue}>
                    <p className={styles.lightText}>{'Estimated monthly drawdown'}</p>
                    <p className={styles.darkText}>
                      {getValue(projections.estimatedPreTaxIncomePerYear / 12, 'Month')}
                    </p>
                  </div>
                  <ButtonLink
                    variant="primary"
                    font="bodyOne"
                    label="How is it estimated?"
                    onClick={() => {
                      dispatchAssumptionsModalClicked();
                      setShowAssumptions(true);
                    }}
                  />
                </div>
              )}
              {!showBalance && (
                <ButtonLink
                  variant="primary"
                  font="bodyOne"
                  label="Why we cannot show your latest balance"
                  onClick={() => setShowCannotShowBalanceModal(true)}
                />
              )}
            </div>
          </div>
          <div className={styles.pensionTransfersWrapper} id="pensionTransfers">
            <p className={styles.subHeading}>{'Pension transfers'}</p>
            <PlanActivityPensionRows />
          </div>
          {instructions.length > 0 && (
          <div className={styles.instructionsWrapper} id="otherInstructions">
            <p className={styles.subHeading}>{'Other instructions'}</p>
            <PlanActivityInstructions />
          </div>
          )}
          <AssumptionsModal
            show={showAssumptions}
            onClose={() => setShowAssumptions(false)}
            subHeadings={getSubHeadings()}
          />
          <NoBalanceModal
            show={showCannotShowBalanceModal}
            onClose={() => setShowCannotShowBalanceModal(false)}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  gender: getGender(state),
  currentAge: getAge(state),
  retirementAge: getIntendedRetirementAge(state),
  estimatedPensionSavings: getLatestPensionValue(state),
  portfolio: getModelDescriptor(state),
  verifiedMonthlyContributions: getVerifiedPersonalContributionsValue(state),
  authoritativeRp: getAuthoritativeRetirementPlan(state),
  ivuCollection: getIVUCollection(state),
  notificationTracking: getNotificationTracking(state),
  uid: getFirebaseUid(state),
  hasViewedPlanActivity: getHasViewedPlanActivity(state),
  activityPlanInstructions: getActivityPlanInstructions(state),
  verifiedNetPersonalContributions: getVerifiedNetPersonalContributions(state),
  verifiedGrossEmployerContributions: getVerifiedGrossEmployerContributions(state),
  cachedRetirementIncomePlanValues: getCachedRetirementIncomePlanValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  getProjectionWithCache: (uid, data, cachedValues) => dispatch(
    getProjectedIncomeWithCache(uid, data, cachedValues, {
      DISPATCHED: PLAN_ACTIVITY_GET_PROJECTION_DISPATCHED,
      FULFILLED: PLAN_ACTIVITY_GET_PROJECTION_FULFILLED,
      REJECTED: PLAN_ACTIVITY_GET_PROJECTION_REJECTED,
    }),
  ),
  dispatchAssumptionsModalClicked: () => dispatch(
    assumptionsModalClicked(PLAN_ACTIVITY_ASSUMPTIONS_MODAL_CLICKED),
  ),
  dispatchPlanActivityPageLoaded: () => dispatch(planActivityPageLoaded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanActivityPage);
