/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { TransitionMotion, spring } from 'react-motion';
import classnames from 'classnames';
import styles from './Modal.css';

const Modal = ({
  show,
  children,
  backDropClick,
  centerContent,
  introModal,
  modalFullMaxWidth,
  foggyBackdrop,
}) => {
  const modalRoot = document.getElementById('modal');

  useEffect(() => {
    document.body.style.overflowY = show ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [show]);

  const willLeave = () => ({ opacity: spring(0), y: spring(-200) });
  const willEnter = () => ({ opacity: 0, y: -400 });

  const transitionMotionStylesArray = show
    ? [{
      key: 'a',
      style: {
        opacity: spring(1),
        y: spring(0),
      },
    }]
    : [];

  if (!modalRoot) {
    return null;
  }

  return ReactDOM.createPortal((
    <TransitionMotion
      willLeave={willLeave}
      willEnter={willEnter}
      styles={transitionMotionStylesArray}
    >
      {(items) => {
        if (items.length) {
          const { key, style } = items[0];
          return (
            <div
              className={classnames(styles.backdrop, {
                [styles.introModal]: introModal,
                [styles.foggyBackdrop]: foggyBackdrop,
              })}
              key={key}
              style={{ opacity: style.opacity }}
              onClick={backDropClick}
            >
              <div
                className={classnames(styles.modal, {
                  [styles.centerContent]: centerContent,
                  [styles.modalFullMaxWidth]: modalFullMaxWidth,
                })}
                style={{ position: 'relative', bottom: style.y, opacity: style.opacity }}
                onClick={(e) => e.stopPropagation()}
              >
                {children}
              </div>
            </div>
          );
        }
        return null;
      }}
    </TransitionMotion>
  ), modalRoot);
};

export default Modal;
